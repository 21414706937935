import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import {
  varFadeInRight,
  varFadeInUp,
  varWrapEnter,
} from "src/components/Animate";
import { pxToRem } from "src/utils/formatFontSize";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: "black",
    [theme.breakpoints.up("md")]: {
      top: 0,
      left: 0,
      width: "100%",
      height: "70vh",
      display: "flex",
      position: "fixed",
      alignItems: "center",
    },
  },
  content: {
    zIndex: 10,
    maxWidth: 700,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      margin: "unset",
      textAlign: "left",
    },
  },
  mainOverlay: {
    zIndex: 9,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    opacity: "0.7 !important",
  },
  mainImg: {
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 8,
    opacity: "0.5 !important",
    width: "100%",
    position: "absolute",
    height: "-webkit-fill-available",
    objectFit: "cover",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
}));

// ----------------------------------------------------------------------

Main.propTypes = {
  className: PropTypes.string,
};

function Main({ className }) {
  const classes = useStyles();

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        variants={varWrapEnter}
        className={clsx(classes.root, className)}
      >
        <motion.img
          alt="gorges du tarn"
          src="/img/noisetiers/main.JPG"
          variants={varFadeInUp}
          className={classes.mainImg}
        />

        <Container maxWidth="lg">
          <div className={classes.content}>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h1" sx={{ color: "common.white" }}>
                Les
                <Typography
                  component="span"
                  variant="h1"
                  sx={{ color: "primary.main" }}
                >
                  &nbsp;Noisetiers
                </Typography>
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Box component="p" sx={{ color: "common.white", py: 5 }}>
                <Typography align="justify" sx={{ fontSize: pxToRem(18) }}>
                  Situé au confluent des gorges du Tarn et de la Jonte, ce gîte
                  est au rez de chaussée d'une vaste maison moderne.
                  <br />
                  Outre son confort et sa luminosité, vous serez séduit par la
                  vue sur les gorges.
                  <br /> Un lieu idéal pour la découverte de cette superbe
                  région du Parc Naturel Régional des Grands Causses, classée au
                  patrimoine mondial de l’UNESCO qui séduira particulièrement
                  les amateurs d’activités de pleine nature (randonnées, canoë,
                  escalade).
                </Typography>
              </Box>
            </motion.div>
          </div>
        </Container>
      </motion.div>
      <Box sx={{ height: { md: "70vh" } }} />
    </>
  );
}

export default Main;

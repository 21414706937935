import clsx from "clsx";
import { Icon } from "@iconify/react";
import Logo from "src/components/Logo";
import React, { useState, useRef } from "react";
import useOffSetTop from "src/hooks/useOffSetTop";
import homeFill from "@iconify-icons/eva/home-fill";
import PopoverMenu from "src/components/PopoverMenu";
import roundSpeed from "@iconify-icons/ic/round-speed";
import menu2Fill from "@iconify-icons/eva/menu-2-fill";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { makeStyles, alpha } from "@material-ui/core/styles";
import {
  Box,
  List,
  Link,
  AppBar,
  Hidden,
  Toolbar,
  MenuItem,
  Container,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { MIconButton } from "src/theme";
import HouseIcon from "@material-ui/icons/House";

// ----------------------------------------------------------------------

const MENU_LINKS = [
  { title: "ACCUEIL", icon: homeFill, href: "/" },
  { title: "LES GITES", icon: roundSpeed, href: "/" },
  { title: "AUX ALENTOURS", icon: roundSpeed, href: "/alentours/" },
  { title: "CONTACT", icon: roundSpeed, href: "/contact/" },
];

const GITES_LINKS = [
  { title: "LES ACACIAS", icon: roundSpeed, href: "/gites/acacias/" },
  { title: "AUX ALIZIERS", icon: roundSpeed, href: "/gites/aliziers/" },
  { title: "LES NOISETIERS", icon: roundSpeed, href: "/gites/noisetiers/" },
  { title: "L'OUSTAL", icon: roundSpeed, href: "/gites/oustal/" },
];

const MENU_MOBILE = [
  { title: "ACCUEIL", icon: homeFill, href: "/" },
  { title: "LES ACACIAS", icon: roundSpeed, href: "/gites/acacias/" },
  { title: "AUX ALIZIERS", icon: roundSpeed, href: "/gites/aliziers/" },
  { title: "LES NOISETIERS", icon: roundSpeed, href: "/gites/noisetiers/" },
  { title: "L'OUSTAL", icon: roundSpeed, href: "/gites/oustal/" },
  { title: "AUX ALENTOURS", icon: roundSpeed, href: "/alentours/" },
  { title: "CONTACT", icon: roundSpeed, href: "/contact/" },
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(["height", "background-color"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up("md")]: {
      height: APP_BAR_DESKTOP,
    },
  },
  isHome: {
    color: theme.palette.common.white,
  },
  isDesktopActive: {
    color: theme.palette.primary.main,
  },
  isMobileActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
  onScroll: {
    "& $toolbar": {
      backgroundColor: theme.palette.background.default,
    },

    "& $isHome": {
      color: theme.palette.text.primary,
    },
    "& $isDesktopActive": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      "& $toolbar": {
        height: APP_BAR_DESKTOP - 20,
      },
    },
  },
  hand: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

// ----------------------------------------------------------------------

function TopBar() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const anchorRefMobile = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const isHome = pathname === "/";
  const isGite = pathname.includes("gites");

  const renderMenuDesktop = (
    <div>
      {MENU_LINKS.map((link) => (
        <>
          {link.title == "LES GITES" ? (
            <>
              <PopoverMenu
                width={220}
                open={openMenu}
                anchorEl={anchorRef.current}
                onClose={() => setOpenMenu(false)}
              >
                <List>
                  {GITES_LINKS.map((link) => (
                    <MenuItem
                      exact
                      to={link.href}
                      key={link.title}
                      component={RouterLink}
                      onClick={() => setOpenMenu(false)}
                      activeClassName={classes.isDesktopActive}
                      sx={{ color: "text.secondary" }}
                    >
                      <HouseIcon icon={link.icon} />
                      <ListItemText>&nbsp;{link.title}</ListItemText>
                    </MenuItem>
                  ))}
                </List>
              </PopoverMenu>
              <Link
                exact
                key={link.title}
                ref={anchorRef}
                onClick={() => setOpenMenu(true)}
                underline="none"
                variant="subtitle2"
                activeClassName={classes.isDesktopActive}
                className={clsx(classes.isHome, classes.hand, {
                  [classes.isDesktopActive]: isGite,
                })}
                sx={{ mr: 5, color: "text.primary" }}
              >
                {link.title}
              </Link>
            </>
          ) : (
            <Link
              exact
              to={link.href}
              key={link.title}
              underline="none"
              variant="subtitle2"
              component={RouterLink}
              activeClassName={classes.isDesktopActive}
              className={classes.isHome}
              sx={{ mr: 5, color: "text.primary" }}
            >
              {link.title}
            </Link>
          )}
        </>
      ))}
    </div>
  );

  const renderMenuMobile = (
    <PopoverMenu
      width={220}
      open={openMenuMobile}
      anchorEl={anchorRefMobile.current}
      onClose={() => setOpenMenuMobile(false)}
    >
      {MENU_MOBILE.map((link) => (
        <MenuItem
          exact
          to={link.href}
          key={link.title}
          component={RouterLink}
          onClick={() => setOpenMenuMobile(false)}
          activeClassName={classes.isMobileActive}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={link.icon} width={20} height={20} />
          </ListItemIcon>
          <ListItemText>{link.title}</ListItemText>
        </MenuItem>
      ))}
    </PopoverMenu>
  );

  return (
    <AppBar
      color="transparent"
      className={clsx(classes.root, { [classes.onScroll]: offset })}
      sx={{ boxShadow: "none" }}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Container
          maxWidth="lg"
          sx={{
            lineHeight: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <Hidden mdDown>{renderMenuDesktop}</Hidden>

          <Hidden mdUp>
            <MIconButton
              ref={anchorRefMobile}
              onClick={() => setOpenMenuMobile(true)}
              className={classes.isHome}
            >
              <Icon icon={menu2Fill} />
            </MIconButton>
            {renderMenuMobile}
          </Hidden>
        </Container>
      </Toolbar>
      {offset && (
        <Box
          sx={{
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            margin: "auto",
            borderRadius: "50%",
            position: "absolute",
            width: `calc(100% - 48px)`,
            boxShadow: (theme) => theme.shadows[25].z8,
          }}
        />
      )}
    </AppBar>
  );
}

export default TopBar;

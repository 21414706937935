import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Page from "src/components/Page";
import Footer from "../Footer";
import Body from "./Body";
import Main from "./Main";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: theme.palette.background.default,
  },
}));

function NoisetiersView() {
  const classes = useStyles();

  return (
    <Page
      title="Gîte gorge du tarn : Les Acacias"
      id="move_top"
      className={classes.root}
    >
      <Main />
      <div className={classes.content}>
        <Body />
        <Footer />
      </div>
    </Page>
  );
}

export default NoisetiersView;

import { Grid, Link, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Call from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import Place from "@material-ui/icons/Place";
import PropTypes from "prop-types";
import React from "react";
import { MotionInView, varFadeInUp } from "src/components/Animate";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  imageScaleSize: {
    width: "100%",
    height: "auto",
  },
  bulletPoint: {
    marginTop: "4px",
  },
  marginB: {
    marginBottom: "96px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "48px",
    },
  },
  profilePhoto: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  workTitle: {
    color: theme.palette.primary.main,
  },
  page: {
    marginTop: "48px",
  },
}));

// ----------------------------------------------------------------------

ContactView.propTypes = {
  className: PropTypes.string,
};

function ContactView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MotionInView variants={varFadeInUp}>
        <Typography variant="h3" align="center" pt={5}>
          RENSEIGNEMENTS & RESERVATION
        </Typography>
      </MotionInView>
      <MotionInView variants={varFadeInUp}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          id="dfdf"
          p={7}
        >
          <Grid
            container
            direction="column"
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid container item justifyContent="center" alignItems="center">
              <AccountCircleIcon
                className={classes.icons}
                style={{ marginRight: "10px" }}
              />
              <Typography variant="body">
                Jean-Marie et Mylène Gabriac
              </Typography>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Call className={classes.icons} style={{ marginRight: "10px" }} />
              <Typography variant="body">
                Téléphone portable : 06 77 05 40 62
              </Typography>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center">
              <MailIcon
                className={classes.icons}
                style={{ marginRight: "10px" }}
              />
              <Typography variant="body">
                jean-mariegabriac@orange.fr
              </Typography>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center">
              <Place
                className={classes.icons}
                style={{ marginRight: "10px" }}
              />
              <Typography variant="body">
                Fontaneilles - 12640 Rivière-sur-Tarn
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          gutterBottom
          align="center"
          style={{ paddingBottom: "55px" }}
        >
          Pour réserver&nbsp;
          <Link href="/contact/" rel="noopener">
            contactez-nous
          </Link>{" "}
          ou faite le directement en ligne sur &nbsp;
          <Link
            href="https://www.gites-de-france-aveyron.com/fr/location/locations-gites-peyreleau-aux-aliziers-h12g005867?capacity=1"
            target="_blank"
            rel="noopener"
          >
            gites de france
          </Link>
          .
        </Typography>
      </MotionInView>
      <Divider variant="middle" />
    </div>
  );
}

export default ContactView;

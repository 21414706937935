import { Card, Container, Grid, Link, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BathtubIcon from "@material-ui/icons/Bathtub";
import BlockIcon from "@material-ui/icons/Block";
import DeckIcon from "@material-ui/icons/Deck";
import FireplaceIcon from "@material-ui/icons/Fireplace";
import HotelIcon from "@material-ui/icons/Hotel";
import HouseIcon from "@material-ui/icons/House";
import KitchenIcon from "@material-ui/icons/Kitchen";
import LocalLaundryServiceIcon from "@material-ui/icons/LocalLaundryService";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import PeopleIcon from "@material-ui/icons/People";
import TvIcon from "@material-ui/icons/Tv";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { MotionInView, varFadeInUp } from "src/components/Animate";
import useBreakpoints from "src/hooks/useBreakpoints";
import Maps from "../Maps";

const rows = [
  createData("3 Nuits", "-", "-", "320 €", "225 €"),
  createData("4/5 Nuits", "-", "-", "360 €", "260 €"),
  createData("6/7 Nuits", "510 €", "480 €", "410 €", "315 €"),
  createData("Nuits Suppl.", "-", "-", "50 €", "35 €"),
];

const photos = [
  {
    src: "/img/noisetiers/1.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/2.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/3.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/4.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/5.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/6.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/7.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/8.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/9.JPG",
    alt: "salon",
  },
  {
    src: "/img/noisetiers/10.JPG",
    alt: "salon",
  },
];

function createData(nuit, ths, hs, ms, bs, msn) {
  return { nuit, ths, hs, ms, bs, msn };
}
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === "light";

  const shadowCard = (opacity) =>
    isLight
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    root: {
      paddingTop: theme.spacing(10),
      [theme.breakpoints.up("md")]: {},
    },
    table: {
      marginTop: theme.spacing(1),
    },
    body: {
      marginBottom: theme.spacing(15),
      marginTop: theme.spacing(15),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
      },
    },
    heading: {
      marginBottom: theme.spacing(10),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    subTitle: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3),
      },
    },
    cardCenter: {
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        "&:before": {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: "auto",
          position: "absolute",
          width: "calc(100% - 40px)",
          height: "calc(100% - 40px)",
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
    hand: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    viewer: {
      zIndex: "2000",
      width: "100px !important",
    },
    photo: {
      width: "100%",
    },
  };
});

// ----------------------------------------------------------------------

BodyHelps.propTypes = {
  className: PropTypes.string,
};

function BodyHelps({ className }) {
  const classes = useStyles();
  const isDesktop = useBreakpoints("up", "lg");

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const dictToList = (list) => {
    var arr = [];
    for (var e in list) {
      console.log(list[e]["src"]);
      arr.push(list[e]["src"]);
    }
    console.log(arr);
    return arr;
  };

  return (
    <div className={clsx(classes.root, className)}>
      {isViewerOpen && (
        <ImageViewer
          src={dictToList(photos)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            zIndex: 2000,
          }}
        />
      )}
      <Container maxWidth="lg">
        <div className={classes.heading}>
          <MotionInView variants={varFadeInUp}>
            <Typography
              gutterBottom
              variant="h3"
              align="left"
              className={classes.typoCenter}
            >
              Gîte idéal
            </Typography>
            <Typography
              gutterBottom
              variant="overline"
              align="left"
              sx={{
                color: "text.secondary",
                display: "block",
                textAlign: "justify",
              }}
            >
              Un lieu idéal pour la découverte de cette superbe région du Parc
              Naturel des Grands Causses
            </Typography>
          </MotionInView>
        </div>

        <Grid container spacing={isDesktop ? 2 : 3} alignItems="center">
          {photos.map((photo, index) => (
            <Grid item xs={12} md={3}>
              <MotionInView variants={varFadeInUp}>
                <Card
                  className={clsx(classes.cardCenter, classes.hand)}
                  onClick={() => openImageViewer(index)}
                >
                  <img
                    src={photo.src}
                    alt={photo.alt}
                    className={classes.photo}
                  />
                </Card>
              </MotionInView>
            </Grid>
          ))}
        </Grid>

        <div className={classes.body}>
          <MotionInView variants={varFadeInUp}>
            <Typography
              gutterBottom
              variant="h3"
              align="left"
              className={(classes.subTitle, classes.typoCenter)}
            >
              Equipements
            </Typography>
            <Grid container>
              <Grid item container direction="column" xs={6} md={4} spacing={2}>
                <Grid item container direction="row">
                  <HouseIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; 65 m²
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <PeopleIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; 5 personnes max
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <LocalParkingIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Parking
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <KitchenIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Cuisine
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <HotelIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; 3 Chambres
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={6} md={4} spacing={2}>
                <Grid item container direction="row">
                  <DeckIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Salon de jardin / Terrasse
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <KitchenIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; refrigerateur
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <OutdoorGrillIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Barbecue
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <LocalLaundryServiceIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Lave-Vaisselle
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <TvIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Télévision
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={6} md={4} spacing={2}>
                <Grid item container direction="row">
                  <BathtubIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; 1 Salle d'eau
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <LocalLaundryServiceIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Machine à Laver
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <FireplaceIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Chauffage d'apoint
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <BlockIcon />
                  <Typography gutterBottom align="left">
                    &nbsp;&nbsp; Animaux non autorisés
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </MotionInView>

          <Typography
            align="left"
            sx={{
              mt: 4,
              color: "text.secondary",
              display: "block",
              textAlign: "justify",
            }}
          >
            Possibilité de louer des draps et linge de maison. Mènage en option.
          </Typography>
        </div>

        <div className={classes.body}>
          <MotionInView variants={varFadeInUp}>
            <Typography
              gutterBottom
              variant="h3"
              align="left"
              className={classes.subTitle}
            >
              TARIFS
            </Typography>
            <Typography gutterBottom align="center">
              Pour réserver&nbsp;
              <Link href="/contact/" rel="noopener">
                contactez-nous
              </Link>{" "}
              ou faite le directement en ligne sur &nbsp;
              <Link
                href="https://www.gites-de-france-aveyron.com/fr/location/locations-gites-peyreleau-aux-acacias-h12g005868?capacity=1"
                target="_blank"
                rel="noopener"
              >
                gites de france
              </Link>
              .
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre de nuits</TableCell>
                    <TableCell align="right">Très Haute Saison</TableCell>
                    <TableCell align="right">Haute Saison</TableCell>
                    <TableCell align="right">Moyenne Saison</TableCell>
                    <TableCell align="right">Basse Saison</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.nuit}>
                      <TableCell component="th" scope="row">
                        {row.nuit}
                      </TableCell>
                      <TableCell align="right">{row.ths}</TableCell>
                      <TableCell align="right">{row.hs}</TableCell>
                      <TableCell align="right">{row.ms}</TableCell>
                      <TableCell align="right">{row.bs}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MotionInView>
        </div>
        <div className={classes.body}>
          <MotionInView variants={varFadeInUp}>
            <Typography
              gutterBottom
              variant="h3"
              align="left"
              className={classes.subTitle}
            >
              Emplacement
            </Typography>
            <Grid item xs={12} md={8} style={{ margin: "0px 0px 25px 0px" }}>
              <Typography gutterBottom variant="body2" align="justify">
                Pour rejoindre le village de Peyreleau où se trouve notre
                location, de l'A75 sortie 44-1 vers Aguessac vous devrez longer
                le Tarn en direction de Rivière-sur-Tarn, Boyne puis le Rozier.
                <br />
                Au milieu du village du Rozier en direction de Meyrueis / Gorges
                de la Jonte, prendre le pont sur la Jonte à droite.
                <br /> Nous sommes ici, de l'autre côté du pont sur la Jonte, au
                pied du site classé de peyreleau à l'entrée exacte des gorges du
                Tarn et de la Jonte.
              </Typography>
            </Grid>
            <Maps position={[44.189717, 3.209593]} />
          </MotionInView>
        </div>
      </Container>
    </div>
  );
}

export default BodyHelps;

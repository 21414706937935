import { Container, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Call from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import Place from "@material-ui/icons/Place";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link as ScrollLink } from "react-scroll";
import {
  MotionInView,
  varFadeInLeft,
  varFadeInRight,
} from "src/components/Animate";
import Logo from "src/components/Logo";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(5, 0),
  },
  footer: {
    paddingTop: theme.spacing(5),
  },
  container: {
    // color: "white",
    // margin: "auto",
    overflow: "hidden",
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
    borderRadius: theme.shape.borderRadiusMd,
    backgroundImage: `linear-gradient(135deg,
      ${theme.palette.primary.main} 0%,
      ${theme.palette.primary.dark} 100%)`,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      maxWidth: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      alignItems: "center",
    },
  },
  seconditem: {
    [theme.breakpoints.down("sm")]: {
      // paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(5),
    },
  },
  icons: {
    color: "#212B36",
  },
  links: {
    color: "white",
  },
}));

// ----------------------------------------------------------------------

Footer.propTypes = {
  className: PropTypes.string,
};

function Footer({ className }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={clsx(classes.root)}>
      <div className={clsx(classes.container, className)}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <MotionInView variants={varFadeInLeft}>
              <Typography align="center" variant="h5" gutterBottom>
                Liens utiles
              </Typography>

              <Typography variant="body2" gutterBottom>
                <Link href="/contact/" rel="noopener" className={classes.links}>
                  Contactez-nous pour réserver
                </Link>
              </Typography>

              <Typography variant="body2" gutterBottom>
                <Link
                  href="https://www.gites-de-france-aveyron.com/fr/location/locations-gites-peyreleau-aux-acacias-h12g005868?capacity=1"
                  target="_blank"
                  rel="noopener"
                  className={classes.links}
                >
                  Réserver sur gites de france
                </Link>
              </Typography>
              <Typography variant="body2" gutterBottom>
                <Link
                  href="https://www.gites-de-france-aveyron.com/fr/location/locations-gites-peyreleau-aux-acacias-h12g005868?capacity=1"
                  target="_blank"
                  rel="noopener"
                  className={classes.links}
                >
                  Les choses à faire
                </Link>
              </Typography>
              <Typography variant="body2" gutterBottom>
                <Link
                  href="https://www.tourisme-aveyron.com/fr/diffusio/organismes/office-de-tourisme-millau-grands-causses-la-caselle-bureau-d-information-touristique-du-rozier-le-rozier_TFO284464354447"
                  target="_blank"
                  rel="noopener"
                  className={classes.links}
                >
                  Office de tourisme
                </Link>
              </Typography>
            </MotionInView>
          </Grid>
          <Grid item className={classes.seconditem}>
            <MotionInView variants={varFadeInRight}>
              <Typography align="center" variant="h5" gutterBottom>
                Contact
              </Typography>
              <Grid container justifyContent="center">
                <AccountCircleIcon
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2" gutterBottom color="white">
                  Jean-Marie et Mylène Gabriac
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Place
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2" gutterBottom color="white">
                  Fontaneilles - 12640 Rivière-sur-Tarn
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <MailIcon
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2" gutterBottom color="white">
                  jean-mariegabriac@orange.fr
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Call
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2" gutterBottom color="white">
                  33(0)6 77 05 40 62
                </Typography>
              </Grid>
            </MotionInView>
          </Grid>
        </Grid>
      </div>

      <Container maxWidth="lg" className={classes.footer}>
        <ScrollLink to="move_top" spy={true} smooth={true}>
          <Logo sx={{ mb: 1, mx: "auto" }} />
        </ScrollLink>

        <Typography variant="caption">
          © All rights reserved
          <br /> Made by &nbsp;
          <Link
            href="https://hippolytebringer.fr/"
            target="_blank"
            rel="noopener"
          >
            Hippolyte Bringer
          </Link>
        </Typography>
      </Container>
    </Container>
  );
}

export default Footer;

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const customMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

function Maps({ position }) {
  return (
    <div>
      <MapContainer center={position} zoom={50} style={{ height: "50vh" }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position} icon={customMarker}>
          <Popup>C'est ici que se trouve la magnifique gîte!</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default Maps;

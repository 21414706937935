import { Card, Container, Grid, Typography } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { alpha, makeStyles } from "@material-ui/core/styles";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  MotionInView,
  varFadeInRight,
  varFadeInUp,
} from "src/components/Animate";
import useBreakpoints from "src/hooks/useBreakpoints";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === "light";

  const shadowCard = (opacity) =>
    isLight
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    root: {
      paddingTop: theme.spacing(10),
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(10),
      },
    },
    heading: {
      marginBottom: theme.spacing(10),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    body: {
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(15),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(10),
      },
    },
    proprioWord: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
    },
    cardCenter: {
      [theme.breakpoints.up("md")]: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        "&:before": {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: "auto",
          position: "absolute",
          width: "calc(100% - 40px)",
          height: "calc(100% - 40px)",
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
    media: {
      height: 200,
    },
  };
});

// ----------------------------------------------------------------------

BodyHelps.propTypes = {
  className: PropTypes.string,
};

function BodyHelps({ className }) {
  const classes = useStyles();
  const isDesktop = useBreakpoints("up", "lg");

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <div className={classes.heading}>
          <MotionInView variants={varFadeInRight}>
            <Typography variant="h2" align="center">
              Le mot des propriétaires
            </Typography>
          </MotionInView>
        </div>
        <Grid container spacing={isDesktop ? 15 : 3}>
          <Grid item xs={12} md={5}>
            <MotionInView variants={varFadeInUp}>
              <Card className={classes.cardCenter}>
                <img src="/img/proprio.JPG" alt="Sallon" width="100%" />
              </Card>
            </MotionInView>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent="center"
            alignItems="center"
          >
            <MotionInView variants={varFadeInUp}>
              <FormatQuoteIcon
                fontSize="large"
                style={{ transform: "scale(-2,2)" }}
              />
              <Typography align="justify" p={5} className={classes.proprioWord}>
                C'est avec plaisir et émotion que nous vous accueillons en ces
                terres exceptionnelles, une invitation à la découverte de notre
                pays, méconnu et préservé, entre Lozère et Aveyron,
                Midi-Pyrénées et Languedoc-Roussillon, entre gorges et Causses,
                à l'entrée de la zone coeur du Parc National des Cévennes.
              </Typography>
              <Typography align="right">
                <FormatQuoteIcon
                  fontSize="large"
                  style={{ transform: "scale(2,2)" }}
                  align="right"
                />
              </Typography>
            </MotionInView>
          </Grid>
        </Grid>
        <div className={classes.body}>
          <MotionInView variants={varFadeInRight}>
            <Typography variant="h2" align="center">
              Les Gîtes
            </Typography>
          </MotionInView>
        </div>
        <Grid container spacing={isDesktop ? 3 : 3}>
          <Grid item xs={12} md={3}>
            <MotionInView variants={varFadeInUp}>
              <CardActionArea component={RouterLink} to={"/gites/acacias/"}>
                <CardMedia
                  className={classes.media}
                  image="/img/acacias/main.JPG"
                  title="Gite gorge du tarn : Les Acacias"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Les Acacias
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Le gîte Les Acacias est au rez de chaussée de la maison, sur
                    2 niveaux, dans le village de Peyreleau.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </MotionInView>
          </Grid>
          <Grid item xs={12} md={3}>
            <MotionInView variants={varFadeInUp}>
              <CardActionArea component={RouterLink} to={"/gites/aliziers/"}>
                <CardMedia
                  className={classes.media}
                  image="/img/aliziers/main.JPG"
                  title="Gite gorge du tarn : Les Aliziers"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Aux Aliziers
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Le gîte Aux Aliziers est situé au rez de chaussée de la
                    maison, au pied du village de Peyreleau sur terrain privatif
                    d'environ 1000 m².
                  </Typography>
                </CardContent>
              </CardActionArea>
            </MotionInView>
          </Grid>
          <Grid item xs={12} md={3}>
            <MotionInView variants={varFadeInUp}>
              <CardActionArea component={RouterLink} to={"/gites/noisetiers/"}>
                <CardMedia
                  className={classes.media}
                  image="/img/noisetiers/main.JPG"
                  title="Gite gorge du tarn : Les Noisetiers"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Les Noisetiers
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Le gîte Les Noisetiers est situé à l'étage de la maison,
                    dans le village de Peyreleau.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </MotionInView>
          </Grid>
          <Grid item xs={12} md={3}>
            <MotionInView variants={varFadeInUp}>
              <CardActionArea component={RouterLink} to={"/gites/oustal/"}>
                <CardMedia
                  className={classes.media}
                  image="/img/oustal/main.JPG"
                  title="Gite gorge du tarn : L'Oustal"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    L'Oustal
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Le gîte à l'Oustal est au rez-de-chaussée de la maison du
                    propriétaire (accès indépendants et opposés), dans un ancien
                    corps de ferme, dans le village de Fontaneilles.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </MotionInView>
          </Grid>
        </Grid>
        {/* <Grid
          className={classes.body}
          container
          justify="center"
          alignItems="center"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <Grid item xs={12} md={3}>
            <motion.div variants={varFadeInRight}>
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                startIcon={<Icon icon={flashFill} width={20} height={20} />}
              >
                Contactez-nous
              </Button>
            </motion.div>
          </Grid>
        </Grid> */}
      </Container>
    </div>
  );
}

export default BodyHelps;

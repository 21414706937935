import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  varFadeInRight,
  varFadeInUp,
  varWrapEnter,
} from "src/components/Animate";
import { pxToRem } from "src/utils/formatFontSize";
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: "black",
    [theme.breakpoints.up("md")]: {
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      display: "flex",
      position: "fixed",
      alignItems: "center",
    },
  },
  content: {
    zIndex: 10,
    maxWidth: 700,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up("md")]: {
      margin: "unset",
      textAlign: "left",
    },
  },
  mainOverlay: {
    zIndex: 9,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    opacity: "0.7 !important",
  },
  mainImg: {
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 8,
    opacity: "0.5 !important",
    width: "100%",
    position: "absolute",
    objectFit: "cover",
    height: "-webkit-fill-available",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
}));

// ----------------------------------------------------------------------

Main.propTypes = {
  className: PropTypes.string,
};

function Main({ className }) {
  const classes = useStyles();

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        variants={varWrapEnter}
        className={clsx(classes.root, className)}
      >
        <motion.img
          alt="gorges du tarn"
          src="/img/main.JPG"
          variants={varFadeInUp}
          className={classes.mainImg}
          style={{ objectFit: "cover", width: "100%" }}
        />

        <Container maxWidth="lg">
          <div className={classes.content}>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h1" sx={{ color: "common.white" }}>
                Gîtes des
                <Typography
                  component="span"
                  variant="h1"
                  sx={{ color: "primary.main" }}
                >
                  &nbsp;Rochers
                </Typography>
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Box
                component="p"
                sx={{
                  color: "common.white",
                  py: 5,
                  fontSize: pxToRem(18),
                  textAlign: "justify",
                }}
              >
                C'est avec plaisir et émotion que nous vous accueillons en ces
                terres exceptionnelles, une invitation à la découverte de notre
                pays, méconnu et préservé, entre Lozère et Aveyron,
                Midi-Pyrénées et Languedoc-Roussillon, entre gorges et Causses,
                à l'entrée de la zone coeur du Parc National des Cévennes.
              </Box>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Box
                component="p"
                sx={{
                  color: "common.white",
                  py: 5,
                  fontSize: pxToRem(18),
                  textAlign: "justify",
                }}
              >
                Situé entre Millau et les Gorges du Tarn, partez à la découverte
                de cette région classée au patrimoine mondial de l’UNESCO.
              </Box>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to="contact/"
                startIcon={<SupervisorAccountIcon width={20} height={20} />}
              >
                Contactez-nous
              </Button>
            </motion.div>
          </div>
        </Container>
      </motion.div>
      <Box sx={{ height: { md: "100vh" } }} />
    </>
  );
}

export default Main;

import React from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import AcaciasView from "src/views/AcaciasView";
import AlentoursView from "src/views/AlentoursView";
import AliziersView from "src/views/AliziersView";
import ContactView from "src/views/ContactView";
import LandingPageView from "src/views/LandingPageView";
import NoisetiersView from "src/views/NoisetiersView";
import OustalView from "src/views/OustalView";

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: "*",
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: "/",
      component: () => <LandingPageView />,
    },
    {
      exact: true,
      path: "/gites/acacias/",
      component: () => <AcaciasView />,
    },
    {
      exact: true,
      path: "/gites/aliziers/",
      component: () => <AliziersView />,
    },
    {
      exact: true,
      path: "/gites/noisetiers/",
      component: () => <NoisetiersView />,
    },
    {
      exact: true,
      path: "/gites/oustal/",
      component: () => <OustalView />,
    },
    {
      exact: true,
      path: "/alentours/",
      component: () => <AlentoursView />,
    },
    {
      exact: true,
      path: "/contact/",
      component: () => <ContactView />,
    },
    {
      component: () => <Redirect to="/" />,
    },
  ],
};

export default HomeRoutes;

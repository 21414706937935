import React from "react";
import { ThemeConfig } from "./theme";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import routes, { renderRoutes } from "src/routes";
import ScrollToTop from "src/components/ScrollToTop";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

const history = createBrowserHistory();

function App() {
  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router history={history}>
          <ScrollToTop />
          {renderRoutes(routes)}
        </Router>
      </LocalizationProvider>
    </ThemeConfig>
  );
}

export default App;

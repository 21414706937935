import { Grid, Link, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Call from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import Place from "@material-ui/icons/Place";
import WebIcon from "@material-ui/icons/Web";
import PropTypes from "prop-types";
import React from "react";
import { MotionInView, varFadeInUp } from "src/components/Animate";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  imageScaleSize: {
    width: "100%",
    height: "auto",
  },
  bulletPoint: {
    marginTop: "4px",
  },
  marginB: {
    marginBottom: "96px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "48px",
    },
  },
  profilePhoto: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  workTitle: {
    color: theme.palette.primary.main,
  },
  page: {
    marginTop: "48px",
  },
  item: {
    flexDirection: {
      xs: "center",
    },
  },
}));

const datas = [
  {
    title: "CANOË KAYAK",
    description:
      "Pour découvrir les Gorges du Tarn, opter pour la location de canoë-kayak en eaux vives ou en famille venez profiter des paysages magnifiques que nous offre cette nature riche des Cévennes. des opérations de constructions tant publiques",
    img: "/img/alentours/canoe.JPG",
    alt: "canoe",
  },
  {
    title: "Via Ferrata / Escalade",
    description:
      "Sur les falaises du Causse noir, au-dessus des Gorges de la Dourbie, la via ferrata vous offre une vue spectaculaire sur le viaduc de Millau et le plateau du Larzac. Très aérienne, elle reste toutefois très ludique selon le parcours choisi. Parcours récemment rénovés avec le filet de 20m de haut et cette sensation d'être suspendu dans le vide.",
    img: "/img/alentours/via_ferrata.jpg",
    alt: "Via ferrata",
  },
  {
    title: "Rando avec les vautours",
    description:
      "Randonnées à la découverte des sites remarquables des gorges du Tarn et de la Jonte, en compagnie des vautours : villages authentiques, gorges profondes, panoramas et points de vues saisissants.",
    img: "/img/alentours/vautour.jpg",
    alt: "vautour",
  },
];

// ----------------------------------------------------------------------

AlentoursView.propTypes = {
  className: PropTypes.string,
};

function AlentoursView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={8}
        my={1}
      >
        {datas.map((data) => (
          <Grid item sx={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={data.img}
                alt={data.alt}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {data.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <MotionInView variants={varFadeInUp}>
        <Typography variant="h3" align="center" pt={5}>
          Les Offices de Tourismes
        </Typography>
      </MotionInView>
      <MotionInView variants={varFadeInUp}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          p={7}
        >
          <Grid
            container
            direction="column"
            item
            md={6}
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="h5" pb={2}>
              Office de Tourisme Gorges du Tarn
            </Typography>
            <Typography align="center">
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                <Place
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                  Causses Cévennes Route de Meyrueis 48150 Le Rozier
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Call
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">05 65 62 60 89</Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <MailIcon
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">ot.lerozier@orange.fr</Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <WebIcon
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                  <Link
                    href="https://www.officedetourisme-gorgesdutarn.com"
                    target="_blank"
                    rel="noopener"
                  >
                    www.officedetourisme-gorgesdutarn.com
                  </Link>
                </Typography>
              </Grid>
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            item
            md={6}
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="h5" pb={2}>
              Office de Tourisme Millau Grands Causses
            </Typography>
            <Typography align="center">
              <Grid container item justifyContent="center" alignItems="center">
                <Place
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                  9 Route de Fontaneilles 12640 Rivière-sur-Tarn
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Call
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">05 65 59 74 28</Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <MailIcon
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                  gorgesdutarn@ot-millau.fr
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <WebIcon
                  className={classes.icons}
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                  <Link
                    href="https://www.millau-viaduc-tourisme.fr"
                    target="_blank"
                    rel="noopener"
                  >
                    www.millau-viaduc-tourisme.fr
                  </Link>
                </Typography>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </MotionInView>
      <Divider variant="middle" />
    </div>
  );
}

export default AlentoursView;
